import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Toast } from '@labourhub/labour-hub-ds'
import {
  ReportSettingItem,
  ReportSettingItemKey,
  useDeleteReportSettingBackgroundImage,
  useGetReportSettings,
  useUpdateReportSettingBackgroundImage,
  useUpdateReportSettings,
} from 'api/settings/report'
import { INITIAL_REPORT_SETTINGS } from 'features/settings/data'
import { reportAccordionSettings } from 'features/settings/data/reportAccordionSettings'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { setReportSettings } from 'features/settings/store'
import { ReportSettingsForm } from 'features/settings/types'

import { Accordion, PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { ImageUpload } from 'components/ui/ImageUpload'
import { cn } from 'utils'

import { BackgroundImageRemoveModal } from './BackgroundImageRemoveModal'

export const ReportSettingsAccordionForm = ({
  type = ReportTypes.REFERENCE,
}: {
  type?: (typeof ReportTypes)[keyof typeof ReportTypes]
}) => {
  const dispatch = useDispatch()
  const [settingsId, setSettingsId] = useState<string>('')
  const [initialFormBody, setInitialFormBody] = useState<ReportSettingItem[]>(
    [],
  )
  const [formBody, setFormBody] = useState<ReportSettingItem[]>([])
  const [bgImage, setBgImage] = useState<File | null>(null)
  const [removeBgImage, setRemoveBgImage] = useState(false)

  const [
    isBackgroundImageRemoveModalActive,
    setIsBackgroundImageRemoveModalActive,
  ] = useState(false)

  const [selectedTextColor, setSelectedTextColor] = useState<string | null>(
    null,
  )

  const [selectedLineColor, setSelectedLineColor] = useState<string | null>(
    null,
  )

  const { refetch, isFetching, isLoading } = useGetReportSettings(
    { reportType: type },
    (data) => {
      setSettingsId(data.id)
      setInitialFormBody(data.reportSettings)
      setFormBody(data.reportSettings)
      updateReportSettingsState(data.reportSettings)
      setRemoveBgImage(false)
    },
  )

  useEffect(() => updateReportSettingsState(formBody), [formBody])

  const updateReportSettingsState = (data: ReportSettingItem[]) => {
    const reportSettingsForm: ReportSettingsForm = {
      ...INITIAL_REPORT_SETTINGS,
    }

    data?.forEach((setting) => {
      switch (setting.id) {
        case ReportSettingItemKey.BackgroundImage:
          reportSettingsForm.backgroundImage = {
            isChecked: setting.isChecked,
            value: setting.value,
          }
          break
        case ReportSettingItemKey.TextColour:
          reportSettingsForm.textColour = setting.value || '#085DA8'
          break
        case ReportSettingItemKey.LineColour:
          reportSettingsForm.lineColour = setting.value || '#1E75C6'
          break
        case ReportSettingItemKey.PrivateReportsEmailPhone:
          reportSettingsForm.privateReportsEmailPhone = setting.isChecked
          break
        case ReportSettingItemKey.PrivateReportsName:
          reportSettingsForm.privateReportsName = setting.isChecked
          break
        default:
          break
      }
    })

    dispatch(setReportSettings(reportSettingsForm))
  }

  const updateFormBody = (id: ReportSettingItemKey, value: any) => {
    setFormBody((prevFormBody) =>
      prevFormBody?.map((setting) =>
        setting.id === id ? { ...setting, value: value } : setting,
      ),
    )
  }

  const getIsChecked = (id: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === id)
    return setting ? setting.isChecked : false
  }

  const getValue = (checkboxId: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === checkboxId)
    return setting ? setting.value : null
  }

  const handleIsCheckedChange = (checkboxId: ReportSettingItemKey) => {
    setFormBody((prevFormBody) => {
      const updatedFormBody = prevFormBody?.map((setting) => {
        if (setting.id === checkboxId) {
          if (
            setting.isChecked &&
            (checkboxId === ReportSettingItemKey.TextColour ||
              checkboxId === ReportSettingItemKey.LineColour)
          ) {
            if (checkboxId === ReportSettingItemKey.TextColour) {
              setSelectedTextColor(null)
            } else if (checkboxId === ReportSettingItemKey.LineColour) {
              setSelectedLineColor(null)
            }

            return {
              ...setting,
              isChecked: !setting.isChecked,
              value: !setting.isChecked ? setting.value : undefined,
            }
          }

          return {
            ...setting,
            isChecked: !setting.isChecked,
          }
        }
        return setting
      })

      // Check if there's actually a change in isChecked before updating state
      if (JSON.stringify(updatedFormBody) !== JSON.stringify(prevFormBody)) {
        updateReportSettingsState(updatedFormBody)
      }

      return updatedFormBody
    })
  }

  const onBgImageUpload = (imageFile: any) => {
    setBgImage(imageFile)
  }

  useEffect(() => {
    if (removeBgImage) {
      updateFormBody(ReportSettingItemKey.BackgroundImage, undefined)
      setBgImage(null)
    }
  }, [removeBgImage])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        getIsChecked(ReportSettingItemKey.TextColour) &&
        selectedTextColor !== null &&
        getValue(ReportSettingItemKey.TextColour) != selectedTextColor
      )
        updateFormBody(ReportSettingItemKey.TextColour, selectedTextColor)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedTextColor, formBody])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        getIsChecked(ReportSettingItemKey.LineColour) &&
        selectedLineColor !== null &&
        getValue(ReportSettingItemKey.LineColour) != selectedLineColor
      )
        updateFormBody(ReportSettingItemKey.LineColour, selectedLineColor)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedLineColor, formBody])

  const { mutate, isLoading: isSaving } = useUpdateReportSettings()
  const { mutate: uploadBgImage, isLoading: isImageUploading } =
    useUpdateReportSettingBackgroundImage()
  const { mutate: deleteImage, isLoading: isDeletingImage } =
    useDeleteReportSettingBackgroundImage()

  const handleSave = () => {
    if (bgImage) {
      uploadBgImage(
        { id: settingsId, backgroundImage: bgImage },
        {
          onSuccess: () => {
            saveReportSettings(formBody)
          },
          onError: () => {
            Toast({
              alertHeader: 'Error while uploading report background image',
              status: 'Error',
            })
          },
        },
      )
    } else if (removeBgImage) {
      deleteImage(
        { id: settingsId, reportType: type },
        {
          onSuccess: () => {
            saveReportSettings(formBody)
            setRemoveBgImage(false)
          },
          onError: () => {
            Toast({
              alertHeader: 'Error while deleting background image',
              status: 'Error',
            })
          },
        },
      )
    } else {
      saveReportSettings(formBody)
    }
  }
  const saveReportSettings = (reportSettings: ReportSettingItem[]) => {
    mutate(
      { reportSettings, reportType: type },
      {
        onSuccess: () => {
          Toast({
            alertHeader: 'Report settings saved successfully!',
            status: 'Success',
          })
        },
        onError: () => {
          Toast({
            alertHeader: 'Error while saving report settings',
            status: 'Error',
          })
        },
        onSettled: () => {
          refetch()
        },
      },
    )
  }

  const handleCancel = () => {
    setFormBody(initialFormBody)
    setSelectedLineColor(null)
    setSelectedTextColor(null)
    setBgImage(null)
    setRemoveBgImage(false)
  }

  const filteredReportAccordionSettings =
    type === ReportTypes.ASSESSMENT
      ? reportAccordionSettings.filter(
          (item) => item.type !== 'privacy-settings',
        )
      : reportAccordionSettings

  return (
    <>
      {(isSaving ||
        isImageUploading ||
        isDeletingImage ||
        isFetching ||
        isLoading) && <PageLoader size='xxs' />}
      <Column className='space-y-5'>
        {filteredReportAccordionSettings.map((accordion, index) => (
          <div>
            <Accordion
              key={index}
              heading={accordion.heading}
              initialState={false}
              description={accordion.description}>
              <div className='w-full mt-5'>
                {accordion.checkboxes.map((checkbox) => (
                  <div key={checkbox.id} className='w-full py-3'>
                    {checkbox.id == ReportSettingItemKey.BackgroundImage ? (
                      <div
                        className={cn('w-full', {
                          'flex-col ':
                            getIsChecked(checkbox.id) && !getValue(checkbox.id),
                          'flex items-center justify-between':
                            getIsChecked(checkbox.id) && getValue(checkbox.id),
                        })}>
                        <Checkbox
                          key={checkbox.id}
                          labelText={checkbox.labelText}
                          helperText=''
                          defaultChecked={getIsChecked(checkbox.id)}
                          onChange={() => handleIsCheckedChange(checkbox.id)}
                        />
                        {getIsChecked(checkbox.id) && (
                          <>
                            {!getValue(checkbox.id) ? (
                              <ImageUpload
                                imageFile={bgImage}
                                className='h-[190px]'
                                showRemoveBtn={false}
                                setImageFile={(imageFile: any) => {
                                  onBgImageUpload(imageFile)
                                  updateFormBody(
                                    ReportSettingItemKey.BackgroundImage,
                                    imageFile.preview,
                                  )
                                }}
                                dropzoneDescription='Drag and drop your custom background image here (PNG or Jpeg).
To get the best results, make sure your image width and height should be 595 * 190px'
                              />
                            ) : (
                              <Button
                                onClick={() => {
                                  setIsBackgroundImageRemoveModalActive(true)
                                }}
                                textTheme='terracotta'
                                theme='link'>
                                Remove
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className='flex items-center justify-between'>
                        <Checkbox
                          key={checkbox.id}
                          labelText={checkbox.labelText}
                          helperText=''
                          defaultChecked={getIsChecked(checkbox.id)}
                          onChange={() => handleIsCheckedChange(checkbox.id)}
                        />
                        {getIsChecked(checkbox.id) && (
                          <>
                            {checkbox.id == ReportSettingItemKey.TextColour && (
                              <input
                                type='color'
                                className='w-[100px]'
                                value={getValue(checkbox.id) || '#085DA8'}
                                onChange={(e) => {
                                  setSelectedTextColor(e.target.value)
                                }}
                              />
                            )}

                            {checkbox.id == ReportSettingItemKey.LineColour && (
                              <input
                                type='color'
                                className='w-[100px]'
                                value={getValue(checkbox.id) || '#1E75C6'}
                                onChange={(e) => {
                                  setSelectedLineColor(e.target.value)
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        ))}
        <div className='flex justify-start mt-5'>
          <Button
            onClick={handleCancel}
            textTheme='black'
            theme='white'
            className='mr-3 cursor-pointer'>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            isLoading={isSaving || isImageUploading || isDeletingImage}
            className='mr-3 cursor-pointer'>
            Save
          </Button>
        </div>
      </Column>

      <BackgroundImageRemoveModal
        isModalActive={isBackgroundImageRemoveModalActive}
        setIsModalActive={setIsBackgroundImageRemoveModalActive}
        setRemoveBgImage={setRemoveBgImage}
      />
    </>
  )
}
