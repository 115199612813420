import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

export type UpdateReportSettingBackgroundImageRequest = {
  id: string
  backgroundImage: File
}

export const useUpdateReportSettingBackgroundImage = () => {
  const mutate = useMutation(
    ({ id, backgroundImage }: UpdateReportSettingBackgroundImageRequest) => {
      const bodyFormData = new FormData()
      bodyFormData.append('BackgroundImage', backgroundImage)
      return putAPIwithFormData(
        `/reports/${id}/background-image`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}
