import { useSelector } from 'react-redux'
import clientLogoPlaceholder from 'assets/images/default-client-logo.svg'
import companyLogoPlaceholder from 'assets/images/default-company-logo.svg'
import { getAgency } from 'features/agencies/store/selectors'

import { RowWithAlignment } from 'components/atoms/Row'
import { cn } from 'utils'

type ReportLogoHeaderProps = {
  clientlogo?: string
  hasClientLogo?: boolean
  hasCompanyLogo?: boolean
  hasCompanyLogoSecondary?: boolean
  isSampleView?: boolean
  logoReverse?: boolean
}

export const ReportLogoHeader = ({
  clientlogo,
  hasClientLogo = false,
  hasCompanyLogo = false,
  hasCompanyLogoSecondary = false,
  isSampleView = false,
  logoReverse = false,
}: ReportLogoHeaderProps) => {
  const { agency } = useSelector(getAgency)
  return (
    <RowWithAlignment
      className={cn({
        'flex-row-reverse': logoReverse,
      })}>
      {isSampleView ? (
        <img
          src={clientLogoPlaceholder}
          alt=''
          className='w-[130px] h-[45px] mb-2'
        />
      ) : hasClientLogo && clientlogo ? (
        <img
          id='clientLogo'
          src={clientlogo}
          alt=''
          className='w-[140px] h-[40px] rounded-md ml-4 my-2'
        />
      ) : (
        <div />
      )}

      {isSampleView ? (
        <img
          src={companyLogoPlaceholder}
          alt=''
          className='w-[130px] h-[45px] mb-2'
        />
      ) : hasCompanyLogo && agency && agency?.logoUrl ? (
        <div className='w-[42px] h-[42px] rounded-full overflow-hidden object-fill mr-4 my-2 '>
          <img id='companyLogoPrimary' src={agency.logoUrl} alt='' />
        </div>
      ) : (hasCompanyLogo || hasCompanyLogoSecondary) &&
        agency &&
        agency?.logoUrl ? (
        <img
          id='companyLogoSecondary'
          src={agency.logoUrl}
          alt=''
          className='w-[40px] h-[40px] rounded-md mr-4 my-2'
        />
      ) : (
        <div />
      )}
    </RowWithAlignment>
  )
}
