import { RootState } from 'store/reducers'

import { AppSettingProps, ReportSettingsForm } from '../types'

import { SettingsStoreProps } from './reducer'

export const getAppSettings = (state: RootState): SettingsStoreProps =>
  state.settings

export const getAgencyAppSettings = (state: RootState): AppSettingProps[] =>
  state.settings.agencyAppSettings

export const getCandidateAppSettings = (state: RootState): AppSettingProps[] =>
  state.settings.candidateAppSettings

export const getReportSettings = (state: RootState): ReportSettingsForm =>
  state.settings.reportSettings
