import { Radar } from 'react-chartjs-2'
import {
  Chart,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'

import { cn } from 'utils'

import { PersonalityTestResponseType } from './PersonalityTestResult'

Chart.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

export default function RadarChart({
  personalityTestResponse: resData,
  isReport = false,
}: {
  personalityTestResponse: PersonalityTestResponseType
  isReport?: boolean
}) {
  const data = {
    labels: [
      'Openness to Experience',
      'Conscientiousness',
      'Extraversion',
      'Agreeableness',
      'Neuroticism',
    ],
    datasets: [
      {
        label: 'candidate',
        data: [
          resData?.opennessToExperience,
          resData?.conscientiousness,
          resData?.extraversion,
          resData?.agreeableness,
          resData?.neuroticism,
        ],
        backgroundColor: 'rgba(7, 91, 169, 0.81)',
        borderColor: 'rgba(7, 91, 169, 0.81)',
        borderWidth: 1,
        pointRadius: 0,
      },
      {
        label: 'ideal',
        data: Array(5).fill(45),
        backgroundColor: 'rgba(234, 67, 53, 0.7)',
        borderColor: 'rgba(234, 67, 53, 0.7)',
        borderWidth: 1,
        pointRadius: 0,
      },
    ],
  }

  const options = {
    responsive: false,
    scales: {
      r: {
        grid: {
          color: '#4B5563', // Change color of the grid lines (web)
          lineWidth: 1, // Change the thickness of the grid lines
        },
        pointLabels: {
          font: {
            size: 16,
            family: 'Inter',
            weight: 600,
          },
          color: '#2E2E2E',
          display: true,
        },
        ticks: {
          display: false,
          count: 5,
        },
        angleLines: {
          display: true,
          color: '#4B5563',
          lineWidth: 1,
        },
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div
      className={cn(
        'bg-white rounded-lg flex mx-auto items-center justify-center h-fit',
        {
          'w-[1200px]': !isReport,
          'w-[745px]': isReport,
        },
      )}>
      <Radar
        data={data}
        options={options}
        width={isReport ? 745 : 800}
        height={465}
      />
    </div>
  )
}
