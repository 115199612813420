import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Radio } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { getPromotionStore } from 'features/promotions'
import {
  FixedSubscriptionPackageDto,
  FixedSubscriptionPackageType,
  getSubscriptionStore,
  planRelationships,
  SubscriptionMode,
  SubscriptionType,
} from 'features/subscriptions'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { Row, RowWithAlignment } from 'components/atoms/Row'
import { formatCurrency } from 'utils'

type SubscriptionTypeRadioProps = {
  currentSubscriptionPackage: FixedSubscriptionPackageDto
  sameplanPackages: FixedSubscriptionPackageDto[]
  setSelectedPackage: any
  setFinalSubscriptionMode: any
}

export const SubscriptionTypeRadio = ({
  currentSubscriptionPackage,
  sameplanPackages,
  setSelectedPackage,
  setFinalSubscriptionMode,
}: SubscriptionTypeRadioProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    SubscriptionType.Monthly,
  )

  const { fixedPromotion } = useSelector(getPromotionStore)

  useEffect(() => {
    setSelectedSubscriptionType(currentSubscriptionPackage.subscriptionType)
  }, [currentSubscriptionPackage])

  // const displayAmount = useMemo(() => {
  //   const amount =
  //     packageDiscount > 0
  //       ? subscriptionPackage.packageAmount * (1 - packageDiscount)
  //       : subscriptionPackage.packageAmount

  //   const formattedOriginalAmount = formatCurrency(
  //     subscriptionPackage.packageAmount,
  //   )
  //   const formattedAmount = formatCurrency(amount)

  //   return (
  //     <span className='text-Gray-300' key='originalAmount'>
  //       {packageDiscount > 0 ? (
  //         <>
  //           <del className='text-Red-600 font-Medium'>
  //             {formattedOriginalAmount}
  //           </del>{' '}
  //           <b className='text-white'>{formattedAmount}</b>{' '}
  //         </>
  //       ) : (
  //         <b className='text-white'>{formattedOriginalAmount}</b>
  //       )}
  //     </span>
  //   )
  // }, [subscriptionPackage])

  const getPromotionDiscount = () => {
    if (fixedPromotion && fixedPromotion?.promotionType === 'Percentage')
      return fixedPromotion?.amount / 100
    return fixedPromotion?.amount
  }

  const saveAmount = useMemo(() => {
    const { monthlyAmount, yearlyAmount } = sameplanPackages.reduce(
      (acc, p) => {
        const discountRate =
          getPromotionDiscount() || p.promotionDiscountRate || 0

        if (p.subscriptionType === SubscriptionType.Monthly) {
          if (fixedPromotion?.promotionType === 'Fixed') {
            acc.monthlyAmount = p.packageAmount - discountRate
          } else {
            acc.monthlyAmount = p.packageAmount * (1 - discountRate)
          }
        } else if (p.subscriptionType === SubscriptionType.Yearly) {
          if (fixedPromotion?.promotionType === 'Fixed') {
            acc.yearlyAmount = p.packageAmount - discountRate
          } else {
            acc.yearlyAmount = p.packageAmount * (1 - discountRate)
          }
        }
        return acc
      },
      { monthlyAmount: 0, yearlyAmount: 0 },
    )

    return monthlyAmount * 12 - yearlyAmount
  }, [sameplanPackages, fixedPromotion])

  const getCurrentPackage = (item: FixedSubscriptionPackageDto) => {
    const promotionDiscount = getPromotionDiscount() || 0

    const finalAmount =
      fixedPromotion?.promotionType === 'Fixed'
        ? item.packageAmount - promotionDiscount
        : item.packageAmount * (1 - promotionDiscount)

    return (
      <>
        {promotionDiscount !== 0 && (
          <del className=' text-Red-600'>
            {formatCurrency(item.packageAmount)}
          </del>
        )}
        <p>
          {formatCurrency(finalAmount)}{' '}
          <span className='text-extra-small font-[300]'>
            Per {item.subscriptionType}
          </span>
        </p>
      </>
    )
  }

  return (
    <div className='flex flex-row-reverse w-full mt-6 gap-x-6'>
      {sameplanPackages.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            setSelectedSubscriptionType(item.subscriptionType)
            setSelectedPackage(item)
            if (fixedSubscription) {
              if (
                fixedSubscription.package.name !=
                FixedSubscriptionPackageType.EnterprisePack
              ) {
                const mode =
                  planRelationships[
                    `${fixedSubscription.package.name}-${fixedSubscription.package.subscriptionType}`
                  ][`${item.name}-${item.subscriptionType}`]

                setFinalSubscriptionMode(mode)
              } else if (
                fixedSubscription &&
                fixedSubscription.package.name ==
                  FixedSubscriptionPackageType.EnterprisePack
              ) {
                setFinalSubscriptionMode(SubscriptionMode.Downgrade)
              } else {
                setFinalSubscriptionMode(SubscriptionMode.New)
              }
            } else {
              setFinalSubscriptionMode(SubscriptionMode.New)
            }
          }}
          className={CN(
            'flex flex-col w-full rounded px-4 py-3 cursor-pointer bg-white',
            {
              'border-[2px] border-Cobalt-600':
                selectedSubscriptionType == item.subscriptionType,
              'border border-Gray-200':
                selectedSubscriptionType != item.subscriptionType,
            },
          )}>
          <RowWithAlignment className='h-[40px]'>
            <div className='flex items-center justify-center h-fit'>
              <Radio
                checked={selectedSubscriptionType == item.subscriptionType}
                id='period'
                className='pb-1 cursor-pointer select-none'
                helperText=''
                labelText=''
              />

              <span className='text-heading-4 font-Bold'>
                {item.subscriptionType === SubscriptionType.Yearly
                  ? 'Annually'
                  : SubscriptionType.Monthly}
              </span>
            </div>

            <div className='w-fit'>
              {item.subscriptionType === SubscriptionType.Yearly && (
                <div className='flex h-fit py-[5px] px-[18px] rounded-md border-[1px] border-[#DD7F0B] font-Bold text-extra-small text-[#DD7F0B] ml-4'>
                  Save ${saveAmount.toFixed(2)}
                </div>
              )}
            </div>
          </RowWithAlignment>

          <Row>
            <ColumnWithAlignment
              itemAlignment='start'
              justifyAlignment='start'
              className='w-full'>
              {getCurrentPackage(item)}
            </ColumnWithAlignment>
          </Row>
        </button>
      ))}
    </div>
  )
}
