/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { Button } from '@labourhub/labour-hub-ds'
import unionIcon from 'assets/images/union.svg'
import CN from 'classnames'

import { getCroppedImg } from './ImageCropAndScale'

import './CustomizableImageUpload.scss'

type CustomizableImageUploadProps = {
  dropzoneDescription: string
  isRectangleLogo?: boolean
  setImageFile: any
  imageLink?: string
  imageFile?: any | null
  setImageUrl?: any
  imageUrl?: any
}

export const CustomizableImageUpload = ({
  dropzoneDescription,
  isRectangleLogo = false,
  setImageFile,
  imageLink,
  imageFile,
  setImageUrl,
  imageUrl,
}: CustomizableImageUploadProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [isNewUploadPanel, setIsNewUploadPanel] = useState(true)
  const [warningMessage, setWarningMessage] = useState('')
  const [droppedFile, setDroppedFile] = useState<File | null>(null)
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true)

  useEffect(() => {
    setZoom(1)
    setIsNewUploadPanel(!imageUrl)
  }, [imageUrl])

  const handleCropChange = useCallback((crop) => setCrop(crop), [])

  const handleCropComplete = useCallback(
    async (_, croppedAreaPixels) => {
      if (zoom !== 1.01 && imageUrl) {
        const croppedImage = await getCroppedImg(
          isRectangleLogo,
          imageUrl,
          croppedAreaPixels,
        )
        setImageFile(croppedImage)
      }
    },
    [imageUrl, zoom, isRectangleLogo, setImageFile],
  )

  const handleZoomChange = useCallback((event) => {
    const newZoom = event.target.value
    setZoom(newZoom)
    setIsResetButtonDisabled(newZoom === '1')
  }, [])

  const handleZoomChangeCrop = useCallback((newZoom) => {
    setZoom(newZoom)
    setIsResetButtonDisabled(newZoom === 1)
  }, [])

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setDroppedFile(file)
      addFile(file)
      setWarningMessage('')
    } else {
      setWarningMessage('Please submit a valid file type (jpg, png, jpeg)')
    }
  }, [])

  const addFile = useCallback(
    (file: File) => {
      const previewUrl = URL.createObjectURL(file)
      setImageFile(file)
      setImageUrl?.(previewUrl)
      setIsNewUploadPanel(false)
    },
    [setImageFile, setImageUrl],
  )

  useEffect(() => {
    if (zoom === 1.01 && droppedFile) {
      addFile(droppedFile)
    }
  }, [zoom, droppedFile, addFile])

  const resetCrop = useCallback(() => {
    setCrop({ x: 0, y: 0 })
    setZoom(1.01)
    setIsResetButtonDisabled(true)
  }, [])

  const removeImage = useCallback(() => {
    setCrop({ x: 0, y: 0 })
    setImageUrl?.(null)
    setImageFile(null)
    setIsNewUploadPanel(true)
  }, [setImageUrl])

  const dropzoneContent = useMemo(
    () => (
      <div className='flex flex-col justify-center items-center space-y-1 pb-4'>
        <img
          src={unionIcon}
          className='px-[26px] py-[10px] h-[100px]'
          alt='drag-and-drop-area'
        />
        <div className='font-Regular text-extra-small text-Gray-400 text-center w-[85%]'>
          {dropzoneDescription}
        </div>
      </div>
    ),
    [dropzoneDescription],
  )
  return (
    <>
      {isNewUploadPanel && !imageUrl && !imageFile && !imageLink ? (
        <div className='w-full mt-4'>
          <div className='border-2 rounded-md cursor-pointer border-Gray-300'>
            <Dropzone multiple={false} onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneContent}
                </div>
              )}
            </Dropzone>
          </div>
          {warningMessage && (
            <div className='flex items-center justify-start w-full py-2 bg-Red-50'>
              <i className='ri-error-warning-line text-Red-500 mr-[11.6px] text-heading-4 ml-6' />
              <span className='text-small text-Red-600 font-Regular'>
                {warningMessage}
              </span>
            </div>
          )}
        </div>
      ) : imageLink ? (
        <div className='flex items-center justify-between w-full mr-10 space-x-4'>
          <img
            src={imageLink}
            alt='drag-and-drop-area'
            className={CN('rounded max-h-[140px] w-full')}
          />
        </div>
      ) : imageUrl || imageFile ? (
        <>
          <div
            className={CN('crop-container relative', {
              'h-[150px] w-full': isRectangleLogo,
              'h-56 w-56': !isRectangleLogo,
            })}>
            {!isNewUploadPanel && (
              <Cropper
                image={imageUrl || imageFile?.preview || ''}
                crop={crop}
                zoom={zoom}
                aspect={isRectangleLogo ? 4 / 1 : 1}
                cropShape={isRectangleLogo ? 'rect' : 'round'}
                showGrid={false}
                restrictPosition={false}
                onCropChange={handleCropChange}
                onCropComplete={handleCropComplete}
                onZoomChange={handleZoomChangeCrop}
              />
            )}
          </div>
          <div className='scaler flex items-center mt-3 mb-[40px] w-80'>
            <i className='ri-indeterminate-circle-fill text-Gray-400' />
            <input
              className='w-full mx-3'
              name='scale'
              type='range'
              onChange={handleZoomChange}
              min='0.1'
              max='2'
              value={zoom}
              step='0.01'
            />
            <i className='ri-add-circle-fill text-Gray-400' />
            <Button
              icon={<i className='ri-delete-bin-line text-Cobalt-600' />}
              size='sm'
              isIconOnly
              className='mx-3'
              onClick={removeImage}
              textTheme='blue'
              theme='white'
            />
            <Button
              icon={
                <i
                  className={CN('ri-refresh-line', {
                    'text-Cobalt-600': !isResetButtonDisabled,
                    'text-Gray-400': isResetButtonDisabled,
                  })}
                />
              }
              isDisabled={isResetButtonDisabled}
              size='sm'
              isIconOnly
              onClick={resetCrop}
              textTheme='blue'
              theme='white'
            />
          </div>
        </>
      ) : (
        <div className='w-full mt-4'>
          <div className='border-2 rounded-md cursor-pointer border-Gray-300'>
            <Dropzone multiple={false} onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {dropzoneContent}
                </div>
              )}
            </Dropzone>
          </div>
          {warningMessage && (
            <div className='flex items-center justify-start w-full py-2 bg-Red-50'>
              <i className='ri-error-warning-line text-Red-500 mr-[11.6px] text-heading-4 ml-6' />
              <span className='text-small text-Red-600 font-Regular'>
                {warningMessage}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  )
}
