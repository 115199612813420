import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

type CreateCustomSurveyRequest = {
  title: string
  description: string
  emailCopy: string
}

export const useCreateCustomSurvey = () => {
  const mutate = useMutation(
    ({ title, description, emailCopy }: CreateCustomSurveyRequest) => {
      const bodyFormData = new FormData()
      bodyFormData.append('title', title)
      if (description) bodyFormData.append('description', description)
      if (emailCopy) bodyFormData.append('emailCopy', emailCopy)
      return postAPIwithFormData(
        `/candidate/CustomSurveyCategory/CustomSurvey`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}
