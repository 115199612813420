import { useState } from 'react'

import { cn } from 'utils'

type ImageViewerProps = {
  imageUrl: string
}
const ImageViewer = ({ imageUrl }: ImageViewerProps) => {
  const [loading, setLoading] = useState(true)
  return (
    <>
      <div className='relative w-1/2'>
        {loading && (
          <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
            <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
          </div>
        )}
        <img
          src={imageUrl}
          alt='drag-and-drop-area'
          className={cn('rounded max-h-[140px] w-full')}
          onLoad={() => setLoading(false)}
        />
      </div>
    </>
  )
}

export default ImageViewer
