import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { get } from 'framework/api/http'

export type ReportSettingsDto = {
  id: string
  reportSettings: ReportSettingItem[]
}

export type ReportSettingItem = {
  id: ReportSettingItemKey
  isChecked: boolean
  value?: string
}

export enum ReportSettingItemKey {
  BackgroundImage = 'backgroundImage',
  TextColour = 'textColour',
  LineColour = 'lineColour',
  PrivateReportsEmailPhone = 'privateReportsEmailPhone',
  PrivateReportsName = 'privateReportsName',
}

type GetReportSettingsProps = {
  reportType: (typeof ReportTypes)[keyof typeof ReportTypes]
}

const getReportSettings = async ({ queryKey }: any) => {
  const { reportType = ReportTypes.REFERENCE } =
    queryKey[1] as GetReportSettingsProps
  const res = await get(`/reports/${reportType}`)
  return res.data
}

export const useGetReportSettings = (
  { ...reqBody }: GetReportSettingsProps,
  onSuccess: (data: ReportSettingsDto) => void,
) => {
  return useQuery(['report_settings', reqBody], getReportSettings, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching report settings',
        status: 'Error',
      })
    },
  })
}
