import { useEffect, useState } from 'react'

import { cn } from 'utils'

import DoughnutChart from './DoughnutChart'
import InfoCard from './InfoCard'
import RadarChart from './RadarChart'

export type PersonalityTestResponseType = {
  agreeableness: number
  conscientiousness: number
  extraversion: number
  neuroticism: number
  opennessToExperience: number
}

const InfoData = [
  {
    icon: 'ri-lightbulb-line',
    heading: 'Openness to Experience',
    description:
      'Openness reflects a person’s level of creativity, curiosity, and willingness to engage with novel ideas and experiences. Individuals high in openness are imaginative, insightful, and open to trying new things. They tend to be curious about the world, appreciate art and beauty, and enjoy intellectual discussions and abstract thinking. These individuals are often more flexible in their thinking and more willing to embrace change and innovation.',
  },
  {
    icon: 'ri-settings-2-line',
    heading: 'Conscientiousness',
    description:
      'Conscientiousness refers to a person’s level of self-discipline, responsibility, and organization. Highly conscientious individuals are reliable, thorough, and efficient. They set high standards for themselves, work hard to achieve their goals, and pay attention to details. They are often seen as dependable and are good at planning and following through on commitments.However, those low in conscientiousness may struggle with organization and consistency, often procrastinating or being more impulsive.',
  },
  {
    icon: 'ri-user-voice-line',
    heading: 'Extraversion',
    description:
      'Extraversion is characterized by sociability, assertiveness, and a tendency to seek out social interaction and stimulation. Extroverts are energetic, talkative, and thrive in the company of others. They are often seen as outgoing and assertive, enjoying group activities and being the center of attention. Extroverts typically experience positive emotions in social settings and are comfortable expressing themselves.',
  },
  {
    icon: 'ri-hearts-line',
    heading: 'Agreeableness',
    description:
      'Agreeableness reflects a person’s tendency to be compassionate, cooperative, and eager to avoid conflict. Individuals high in agreeableness are empathetic, considerate, and value social harmony. They are often trusting, forgiving, and willing to compromise for the sake of maintaining good relationships. People with high agreeableness are typically seen as warm and friendly. In contrast, those low in agreeableness may be more competitive, critical, or skeptical of others.',
  },
  {
    icon: 'ri-emotion-unhappy-line',
    heading: 'Neuroticism',
    description:
      'Neuroticism refers to the tendency to experience negative emotions such as anxiety, anger, and depression. Individuals high in neuroticism are more likely to be emotionally reactive and may struggle to manage stress effectively. They often experience mood swings, worry excessively, and are more sensitive to criticism or setbacks. These individuals may find it harder to cope with life’s challenges and may be more prone to feelings of frustration or dissatisfaction. ',
  },
]

export default function PersonalityTestResult({
  reportViewData,
  isReport = false,
  isPrint = false,
  position = 0,
}: {
  reportViewData: any
  isReport?: boolean
  isPrint?: boolean
  position?: number
}) {
  const [personalityTestResponse, setPersonalityTestResponse] = useState<
    PersonalityTestResponseType | undefined
  >()
  useEffect(() => {
    setPersonalityTestResponse(reportViewData?.personalityTestResponse)
  }, [reportViewData])
  return (
    <div
      className={cn('relative styled-scroll bg-Gray-50  overflow-y-auto ', {
        'w-[1261px] p-6 ': !isReport,
        'w-[747px] mx-auto': isReport,
      })}>
      <h1
        className={cn(
          'bg-Cobalt-50 text-Cobalt-800  font-Medium my-4 pl-4 h-9 flex items-center text-left',
          {
            'text-heading-5': !isReport,
            'text-[14px] leading-6': isReport,
          },
        )}>
        Guidance on personality for the given position / comparison to
        assessment data
      </h1>
      <p
        className={cn(
          '  font-Regular my-7 pl-4 pr-4  flex items-center text-left',
          {
            'text-base': !isReport,
            'text-[12px] leading-6 w-[745px]': isReport,
          },
        )}
        style={{
          pageBreakAfter: position !== 1 ? 'always' : 'avoid',
        }}>
        a customer-facing position might prioritize traits such as extroversion,
        empathy, and adaptability, while an analytical role may require
        conscientiousness, attention to detail, and critical thinking. Comparing
        these traits to assessment data involves analyzing how the candidate’s
        personality fits with the expected behaviors for the role. If the
        assessment shows a high level of conscientiousness but low extroversion,
        this might indicate that the individual is well-suited for a
        detail-oriented role but may need development in areas involving
        teamwork or social interaction, depending on the job requirements. This
        comparison helps determine the candidate’s alignment with the position
        and any potential gaps to address. a customer-facing position might
        prioritize traits such as extroversion, empathy, and adaptability, while
        an analytical role may require conscientiousness, attention to detail,
        and critical thinking. Comparing these traits to assessment data
        involves analyzing how the candidate’s personality fits with the
        expected behaviors for the role. If the assessment shows a high level of
        conscientiousness but low extroversion, this might indicate that the
        individual is well-suited for a detail-oriented role but may need
        development in areas involving teamwork or social interaction, depending
        on the job requirements. This comparison helps determine the candidate’s
        alignment with the position and any potential gaps to address.
      </p>
      <div
        className={cn('break-inside-avoid', {
          'h-fit w-full': !isReport,
          'min-h-[600px] h-fit w-[747px]': isReport,
        })}
        style={{
          pageBreakBefore: position === 1 ? 'always' : 'avoid',
          paddingTop: isPrint ? '32px' : '0',
        }}>
        <h1 className='bg-Cobalt-50 text-Cobalt-800 mb-6 text-heading-5 font-Medium my-4 pl-4 h-9 flex items-center text-left'>
          Individual and Normal Comparison
        </h1>
        {personalityTestResponse && (
          <div
            className={cn('flex flex-row gap-2 mb-10 ', {
              'flex-nowrap': !isReport,
              'flex-wrap': isReport,
            })}>
            {/* {personalityTestResponse &&
                Object.keys(personalityTestResponse).map((key) => (
                  <DoughnutChart
                    icon={
                      InfoData.find((data) => data.heading === key)?.icon || ''
                    }
                    average={}
                    key={key}
                    value={personalityTestResponse[key]}
                    heading={key}
                  />
                ))} */}
            <DoughnutChart
              icon='ri-emotion-unhappy-line'
              heading='Neuroticism'
              average={45}
              value={personalityTestResponse?.neuroticism}
            />
            <DoughnutChart
              icon='ri-settings-2-line'
              heading='Conscientiousness'
              average={45}
              value={personalityTestResponse?.conscientiousness}
            />
            <DoughnutChart
              icon='ri-hearts-line'
              heading='Agreeableness'
              average={45}
              value={personalityTestResponse?.agreeableness}
            />
            <DoughnutChart
              icon='ri-user-voice-line'
              heading='Extraversion'
              average={45}
              value={personalityTestResponse?.extraversion}
            />
            <DoughnutChart
              icon='ri-lightbulb-line'
              heading='Openness to Experience'
              average={45}
              value={personalityTestResponse?.opennessToExperience}
            />
          </div>
        )}
      </div>
      <div
        className={cn('break-inside-auto', {
          'h-fit w-full': !isReport,
          'min-h-[600px] h-fit w-[747px] ': isReport,
        })}
        style={{
          pageBreakBefore: 'always',
          paddingTop: isPrint ? '32px' : '0',
        }}>
        <h1
          className={cn(
            'bg-Cobalt-50  text-Cobalt-800 mb-8  font-Medium my-4 pl-4 h-9 flex items-center text-left',
            {
              'text-heading-5': !isReport,
              'text-[14px] leading-7': isReport,
            },
          )}>
          Big-Five Factor Markers (BFFM)
        </h1>
        {personalityTestResponse && (
          <RadarChart
            isReport={isReport}
            personalityTestResponse={personalityTestResponse}
          />
        )}
      </div>
      <h1
        className={cn(
          'bg-Cobalt-50 text-Cobalt-800  font-Medium my-4 pl-4 h-9 flex items-center text-left',
          {
            'text-heading-5 w-full': !isReport,
            'text-[14px] leading-7 w-[747px]': isReport,
          },
        )}>
        Big-Five Factor Markers (BFFM)
      </h1>
      {InfoData.map((data) => (
        <InfoCard
          icon={data.icon}
          heading={data.heading}
          description={data.description}
          isReport={isReport}
          key={data.heading}
        />
      ))}
    </div>
  )
}
