/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { breadcrumbData } from 'static-data/breadcrumbData'
import { setBreadCrumbs } from 'store/reducers/breadcrumbs/breadcrumbs'

type BreadcrumbProps = {
  [x: string]: any
  className?: string
  isCustomStyles?: boolean
}

export const Breadcrumb = ({
  className,
  isCustomStyles,
  ...restProps
}: BreadcrumbProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { breadcrumbs } = useSelector((state: any) => state.breadcrumbs)
  const BreadcrumbTextClassName = !isCustomStyles
    ? CN('text-Gray-600 font-Regular text-extra-small pr-2')
    : className

  const LastBreadcrumbTextClassName = !isCustomStyles
    ? CN('text-Cobalt-600 font-Regular text-extra-small')
    : className

  const handleBreadcrumbClick = (link: string) => {
    navigate(link)
  }

  const [breadcrumbList, setBreadcrumbList] = useState<any>([])
  const [searchParams] = useSearchParams()
  const params = useParams()

  const getTitle = (item: string) => {
    if (breadcrumbData[item]) {
      return breadcrumbData[item].text
    }
    return item
  }

  const getSubTitle = (item: string) => {
    if (!breadcrumbData[item]) return ''

    if (breadcrumbData[item].hasQueryParam) {
      return searchParams.get(breadcrumbData[item].subText)
    }
    return breadcrumbData[item].subText
  }

  useEffect(() => {
    const pathnames = location.pathname.split('/').slice(1)
    const fullUrl = location.href.split('/').slice(3)

    const temp = fullUrl.map((item: any, index: any) => {
      if (pathnames.find((xItem: any) => xItem === item)) {
        if (item === 'user-list') {
          return {
            title: breadcrumbs[1]?.title || getTitle(item),
            link: breadcrumbs[1]?.link || '',
            clickable: breadcrumbs[1]?.link ? true : false,
          }
        }

        if (
          item != 'super-admin' &&
          fullUrl.includes('super-admin') &&
          params.tenantId != null
        ) {
          return {
            title: getTitle('super-admin/*'),
            link: `/${fullUrl.slice(0, index + 1).join('/')}`,
            clickable: false,
          }
        }

        return {
          title: getTitle(item) || '',
          link: `/${fullUrl.slice(0, index + 1).join('/')}`,
          clickable: true,
        }
      }

      return {
        title:
          `${
            pathnames.includes('user-list')
              ? `${getSubTitle(fullUrl[index - 1])}s`
              : getSubTitle(fullUrl[index - 1])
          }` || '',
        link: `/${fullUrl.slice(0, index + 1).join('/')}`,
        clickable: false,
      }
    })

    setBreadcrumbList(temp)
    dispatch(setBreadCrumbs(temp))
  }, [window.location.href])

  return (
    <div className='flex ParentClass' {...restProps}>
      {breadcrumbList?.map((item: any, index: any) => (
        <div
          className={CN('flex items-center justify-center cursor-pointer', {
            'cursor-not-allowed': item.clickable === false,
          })}
          key={index}>
          {index !== 0 &&
            (index === breadcrumbList.length - 1 ? (
              <i className='w-5 text-center ri-arrow-right-s-line text-small text-Cobalt-600'></i>
            ) : (
              <i className='w-5 text-center ri-arrow-right-s-line text-small text-Gray-600'></i>
            ))}

          {index === breadcrumbList.length - 1 ? (
            <div className={LastBreadcrumbTextClassName}>{item.title}</div>
          ) : (
            <a
              onClick={() => item.clickable && handleBreadcrumbClick(item.link)}
              className={BreadcrumbTextClassName}>
              {item.title}
            </a>
          )}
        </div>
      ))}
    </div>
  )
}
