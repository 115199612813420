import React from 'react'

import {
  CombinedReportNameHeader,
  ReportFooter,
} from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AutoMarkingProgress from '../AutoMarkingProgress'
import { AssesmentAnswerRatingView } from '../DownloadReport/AssesmentAnswerRatingView'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type AssessmentReportPreviewSectionProps = {
  markingValue: any
  reportViewData: any
  setIsWebcamSnapsView: React.Dispatch<React.SetStateAction<boolean>>
  setWebcamSnapsViewImages?: React.Dispatch<React.SetStateAction<never[]>>
  position?: number
}

const AssessmentReportPreviewSection = ({
  markingValue,
  reportViewData,
  setIsWebcamSnapsView,
  setWebcamSnapsViewImages,
  position = 0,
}: AssessmentReportPreviewSectionProps) => {
  const { assessmentAutoMarking, reportQuestion } = reportViewData

  return (
    <>
      <div className='m-3'>
        <div className='h-auto pb-4'>
          {position !== 1 && <CombinedReportNameHeader data={reportViewData} />}
          <AutoMarkingProgress
            values={markingValue}
            isReport={true}
            totalMarks={assessmentAutoMarking?.totalMarks}
          />
        </div>
        <div className='h-auto pb-4'>
          <AssessmentCompletedSection reportViewData={reportViewData} />
        </div>
        <div className='h-auto pb-4'>
          <AntiCheatinMonitor
            reportViewData={reportViewData}
            isReport={true}
            setIsWebcamSnapsView={setIsWebcamSnapsView}
            setWebcamSnapsViewImages={
              setWebcamSnapsViewImages && setWebcamSnapsViewImages
            }
          />
        </div>
      </div>

      <div className='px-6 font-Medium text-base'>RESPONSES</div>
      <div className='px-6'>
        {reportQuestion.map((item, idx) => (
          <AssesmentAnswerRatingView
            selectedTemplate={item}
            key={idx}
            isPrint={false}
          />
        ))}
      </div>
      <ReportFooter isFixed={true} />
    </>
  )
}

export default AssessmentReportPreviewSection
