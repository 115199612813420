import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSortable } from '@dnd-kit/sortable'
import {
  Checkbox,
  Radio,
  SimpleSelect,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { motion } from 'framer-motion'

import { Rate } from 'components/atoms'
import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'
import { RowWithAlignment } from 'components/atoms/Row'
import { cn } from 'utils'
import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

import { DragButton } from './DragButton'
import ImageUploadModal from './ImageUploadModal'
import VideoUploadModal from './VideoUploadModal'

export type DragOption = {
  isDragging: boolean
  activeIndex?: number
  overIndex?: number
}

type DraggableQuestionCardProps = {
  index: number
  filteredIndex: number
  question: AssessmentQuestion
  onClickEditButton: () => void
  onClickDeleteButton: () => void
  isReadOnly?: boolean
  dragOptions: DragOption
  setDragOptions: React.Dispatch<React.SetStateAction<DragOption>>
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
}

export const DraggableQuestionCard = ({
  index,
  filteredIndex,
  question,
  onClickEditButton,
  onClickDeleteButton,
  isReadOnly = true,
  dragOptions,
  setDragOptions,
  updateQuestion,
}: DraggableQuestionCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
    activeIndex,
    overIndex,
  } = useSortable({
    id: question.id,
  })
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [isImageModelOpen, setIsImageModelOpen] = useState(false)
  const [isVideoModelOpen, setIsVideoModelOpen] = useState(false)

  useEffect(() => {
    if (
      question.type === 'checkbox' &&
      question.correctAnswer !== '' &&
      question.correctAnswer !== null &&
      question.correctAnswer !== undefined
    ) {
      try {
        const storedCheckboxes = JSON.parse(question.correctAnswer) || []
        setCheckedItems(storedCheckboxes)
      } catch (error) {
        console.error('Error parsing JSON:', error)
        setCheckedItems(checkedItems)
      }
    }
  }, [question.correctAnswer, question.type])

  useEffect(
    () =>
      setDragOptions({
        isDragging,
        activeIndex,
        overIndex,
      }),
    [isDragging, activeIndex, overIndex],
  )

  const formattedIndex =
    filteredIndex === -1
      ? null
      : filteredIndex >= 0 && filteredIndex <= 8
      ? `0${filteredIndex + 1}`
      : `${filteredIndex + 1}`

  return (
    <div className='bg-white p-5 rounded-md'>
      <motion.div
        className={cn(
          'relative group w-full h-fit flex flex-col rounded-lg border border-Gray-300 bg-white hover:border-Cobalt-200 select-none',
          {
            'border-dashed': !isDragging,
          },
        )}
        ref={setNodeRef}
        layoutId={String(question.id)}
        style={{
          transform: transform?.[index]
            ? `translateY(${transform[index]}px)`
            : 'none',
          opacity: isDragging && index === overIndex ? 0.5 : 1,
        }}>
        {/* top index and tag section */}
        <RowWithAlignment className='w-full border-b border-dashed px-4 py-3 border-Gray-300 hover:border-Cobalt-200'>
          {/* badge section */}
          <div
            className={cn(
              'flex items-center justify-center pl-2 pr-3 py-1 rounded-full font-Medium text-extra-small bg-Gray-400 group-hover:bg-Cobalt-400',
              {
                'bg-Cobalt-400': isDragging,
              },
            )}>
            <i
              className={cn('text-[14px] mr-2 text-Cobalt-50', question.icon)}
            />
            <span className='text-Gray-50 '>{question.name}</span>
          </div>
          {/* button section */}
          <div className='flex items-center justify-evenly space-x-2 invisible group-hover:visible'>
            <DragButton
              className='w-[60px] space-x-1'
              onClick={onClickEditButton}>
              <i className='ri-pencil-line' />
              <span className='text-extra-small'>Edit</span>
            </DragButton>

            <DragButton
              disabled={question.imageUrl}
              onClick={() => {
                setIsVideoModelOpen(true)
              }}>
              {question.imageUrl ? (
                <>
                  <i
                    className='ri-play-circle-fill'
                    data-tip='If you have already uploaded an image and want to upload a video instead, first remove the current image to enable video upload.'
                    data-for='select-video-link-1'
                  />
                  <ReactTooltip
                    place='top'
                    id='select-video-link-1'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#1F2937'
                    className='w-[220px] text-extra-small'
                  />
                </>
              ) : (
                <>
                  <i
                    className='ri-play-circle-fill'
                    data-tip='Add a Video'
                    data-for='select-video-link-2'
                  />
                  <ReactTooltip
                    place='top'
                    id='select-video-link-2'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#1F2937'
                    className='w-[220px] text-extra-small'
                  />
                </>
              )}
            </DragButton>

            <DragButton
              disabled={question.videoLink}
              onClick={() => {
                setIsImageModelOpen(true)
              }}>
              {question.videoLink ? (
                <>
                  <i
                    className='ri-image-line'
                    data-tip='If you have already uploaded a video and want to upload an image instead, first remove the current video to enable image upload.'
                    data-for='select-image-icon-1'
                  />
                  <ReactTooltip
                    place='top'
                    id='select-image-icon-1'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#1F2937'
                    className='w-[220px] text-extra-small'
                  />
                </>
              ) : (
                <>
                  <i
                    className='ri-image-line'
                    data-tip='Add an Image'
                    data-for='select-image-icon-2'
                  />
                  <ReactTooltip
                    place='top'
                    id='select-image-icon-2'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#1F2937'
                    className='w-[220px]  text-extra-small'
                  />
                </>
              )}
            </DragButton>

            <DragButton
              onClick={onClickDeleteButton}
              className='hover:!text-Red-600 hover:!bg-Red-50 hover:!border-Red-200'>
              <i
                className='ri-delete-bin-line'
                data-tip='Delete'
                data-for='card-delete-icon'
              />
              <ReactTooltip
                place='top'
                offset={{ top: 20, right: 10 }}
                id='card-delete-icon'
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#1F2937'
              />
            </DragButton>

            <DragButton {...listeners} {...attributes}>
              <i
                className='ri-drag-move-2-fill'
                data-tip='Drag'
                data-for='Drag'
              />
              <ReactTooltip
                place='top'
                id='Drag'
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#1F2937'
              />
            </DragButton>
          </div>
        </RowWithAlignment>

        <div
          className={cn(
            'flex flex-col items-start justify-start p-4 space-y-4',
            {
              'pointer-events-none': isReadOnly,
            },
          )}>
          {question.type != 'heading' && question.type != 'paragraph' && (
            <div className=' px-2 py-1 max-w-[120px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
              Question {formattedIndex}
            </div>
          )}

          <p
            className={cn('w-full text-Gray-800 whitespace-pre-wrap', {
              'text-[1.2rem] font-[700]': question.type === 'heading',
              'font-Medium': question.type != 'heading',
              'whitespace-pre-line': question.type == 'paragraph',
              "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
                question.isRequired,
            })}>
            {question.title}
          </p>

          <div className='w-full flex space-x-3 justify-evenly items-center'>
            {question.imageUrl && <ImageViewer imageUrl={question.imageUrl} />}
            {question.videoLink && <IFrameVideo link={question.videoLink} />}

            <div
              className={`${
                question.imageUrl || question.videoLink ? 'w-1/2' : 'w-full'
              }`}>
              {question.type === 'text' && (
                <TextField
                  className='w-full'
                  isDisabled={true}
                  value=''
                  placeholder=''
                />
              )}

              {question.type === 'text_area' && (
                <TextArea
                  className='w-full'
                  value=''
                  isDisabled={true}
                  placeholder=''
                  rows={4}
                />
              )}

              {question.type === 'textarea' && (
                <TextArea
                  className='w-full'
                  value=''
                  isDisabled={true}
                  placeholder=''
                  rows={4}
                />
              )}

              {question.type === 'rating' && (
                <Rate rating='0' count={Number(question.meta.length || '5')} />
              )}

              {question.type === 'checkbox' && (
                <div className='flex flex-col gap-y-2'>
                  {[...question.meta].map((item, idx) => (
                    <Checkbox
                      key={idx}
                      id={`custom-checkbox-00${idx}`}
                      labelText={item}
                      labelTextClassName='font-Regular'
                      checkboxClassName='ml-0'
                    />
                  ))}
                </div>
              )}

              {question.type === 'select' && (
                <SimpleSelect
                  className='w-full'
                  value={null}
                  options={[]}
                  placeholder='Select Option'
                />
              )}

              {question.type === 'radio' && (
                <div className='flex gap-6'>
                  <Radio
                    id={`yesss_${question.id}`}
                    className='cursor-pointer select-none'
                    name={`status_${question.id}`}
                    helperText=''
                    labelText='Yes'
                  />

                  <Radio
                    className='cursor-pointer select-none'
                    id={`nooo_${question.id}`}
                    name={`status_${question.id}`}
                    helperText=''
                    labelText='No'
                  />
                </div>
              )}

              {(question.type === 'radio_neg_detail' ||
                question.type === 'radio_pos_detail') && (
                <div className='flex flex-col w-full'>
                  <div className='flex gap-6'>
                    <Radio
                      id={`0${question.id}-detailed-yes`}
                      className='cursor-pointer select-none'
                      name={`0${question.id}-detailed`}
                      helperText=''
                      labelText='Yes'
                    />

                    <Radio
                      className='cursor-pointer select-none'
                      id={`0${question.id}-detailed-yes`}
                      name={`0${question.id}-detailed`}
                      helperText=''
                      labelText='No'
                    />
                  </div>

                  <TextArea
                    className='w-full mt-3'
                    isDisabled={true}
                    label='Explain'
                    value=''
                    placeholder=''
                    rows={4}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
      <div
        className={cn(
          'mt-2 border-dashed pointer-events-none w-full h-fit flex flex-col items-start justify-start space-y-4 rounded-lg border border-Red-500 bg-Gray-50 select-none p-4',
          {
            hidden:
              dragOptions.isDragging ||
              question.type == 'heading' ||
              question.type == 'paragraph',
          },
        )}>
        <div className='flex justify-between w-full'>
          <div className=' px-2 py-1 max-w-[180px] border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
            Correct Answer {formattedIndex}
          </div>
          <div className='font-Medium w-5 h-5 text-heading-4 pointer-events-auto'>
            <i
              className='ri-information-line'
              data-tip='The correct answer is used only to grade the applicant and will not be shown to the candidate at any point. Please provide detailed descriptions where possible to achieve the best results and for future reference.'
              data-for='answer-information'
            />
            <ReactTooltip
              place='left'
              id='answer-information'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#1F2937'
              className='min-h-[100px] w-[400px]'
            />
          </div>
        </div>

        <p
          className={cn('w-full text-Gray-800 whitespace-pre-wrap', {
            'text-[1.2rem] font-[700]': question.type === 'heading',
            'font-Medium': question.type != 'heading',
            'whitespace-pre-line': question.type == 'paragraph',
            "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
              question.isRequired,
          })}>
          {question.title}
        </p>

        <div className='w-full flex space-x-3 justify-evenly items-center'>
          {question.imageUrl && (
            <div className='w-1/2'>
              <img
                src={question.imageUrl}
                alt='drag-and-drop-area'
                className={cn('rounded max-h-[140px] w-full')}
              />
            </div>
          )}
          {question.videoLink && (
            <div className='w-1/2 pointer-events-auto'>
              <iframe
                height={235}
                className='w-full'
                src={setEmbededVideoLink(question.videoLink)}
                title='Video Preview'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          )}
          <div
            className={`${
              question.imageUrl || question.videoLink ? 'w-1/2' : 'w-full'
            }`}>
            {question.type === 'text' && (
              <TextField
                className='w-full'
                isDisabled={true}
                value={question.correctAnswer}
                placeholder=''
              />
            )}

            {question.type === 'text_area' && (
              <TextArea
                className='w-full'
                value={question.correctAnswer}
                isDisabled={true}
                placeholder=''
                rows={4}
              />
            )}

            {question.type === 'textarea' && (
              <TextArea
                className='w-full'
                value={question.correctAnswer}
                isDisabled={true}
                placeholder=''
                rows={4}
              />
            )}

            {question.type === 'rating' && (
              <Rate
                rating={question.correctAnswer}
                count={Number(question.meta.length || '5')}
              />
            )}

            {question.type === 'checkbox' && (
              <div className='flex flex-col gap-y-2'>
                {[...question.meta].map((item, idx) => {
                  return (
                    <Checkbox
                      key={idx}
                      id={`custom-checkbox-0${idx}`}
                      labelText={item}
                      checked={checkedItems.includes(item)}
                      labelTextClassName='font-Regular'
                      checkboxClassName='ml-0'
                    />
                  )
                })}
              </div>
            )}

            {question.type === 'select' && (
              <SimpleSelect
                className='w-full'
                value={{
                  value: question.correctAnswer,
                  label: question.correctAnswer,
                }}
                options={question.meta.map((option) => ({
                  value: option,
                  label: option,
                }))}
                placeholder='Select Option'
              />
            )}

            {question.type === 'radio' && (
              <div className='flex gap-6'>
                <Radio
                  id={`StatusYes_${question.id}`}
                  checked={question.correctAnswer === 'yes'}
                  className='cursor-pointer'
                  name={`YesNoStatus_${question.id}`}
                  helperText=''
                  labelText=' Yes'
                />

                <Radio
                  className='cursor-pointer'
                  id={`StatusYes_${question.id}`}
                  checked={question.correctAnswer === 'no'}
                  name={`YesNoStatus_${question.id}`}
                  helperText=''
                  labelText=' No'
                />
              </div>
            )}

            {(question.type === 'radio_neg_detail' ||
              question.type === 'radio_pos_detail') && (
              <div className='flex flex-col w-full'>
                <div className='flex gap-6'>
                  <Radio
                    checked={question.type === 'radio_pos_detail'}
                    id={`${question.id}-detailed-yes`}
                    className='cursor-pointer select-none'
                    name={`00${question.id}-detailed`}
                    helperText=''
                    labelText='Yes'
                  />

                  <Radio
                    checked={question.type === 'radio_neg_detail'}
                    className='cursor-pointer select-none'
                    id={`${question.id}-detailed-yes`}
                    name={`00${question.id}-detailed`}
                    helperText=''
                    labelText='No'
                  />
                </div>

                <TextArea
                  className='w-full mt-3'
                  isDisabled={true}
                  label='Explain'
                  value={question.correctAnswer}
                  placeholder=''
                  rows={4}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoUploadModal
        isModalActive={isVideoModelOpen}
        setIsModalActive={setIsVideoModelOpen}
        updateQuestion={updateQuestion}
        question={question}
      />

      <ImageUploadModal
        isModalActive={isImageModelOpen}
        setIsModalActive={setIsImageModelOpen}
        updateQuestion={updateQuestion}
        question={question}
      />
    </div>
  )
}
